export const beamExperience: string[] = [
  "Full Stack Software Engineer",
  "Quoting Platform",
  "2022 - 2024",
];

export const rootExperience: string[] = [
  "Front End Software Engineer",
  "Productivity Team",
  "2021-2022",
];

export const ccsExperience: string[] = [
  "Full Stack Software Engineer",
  "Grails Team",
  "2017-2021",
];
